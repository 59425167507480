<template>
    <div class="index">
        <el-container class="container">
            <!--      左侧导航      -->
            <Nav />
            <el-container class="main-box">
                <el-main>
                    <router-view></router-view>
                </el-main>
            </el-container>
        </el-container>
    </div>
</template>

<script>
import Nav from "./Nav";
import { Organization } from '../../components/HospitalDomain/Organization';
import { UserTitle } from '../../components/HospitalDomain/UserTitle';
import { UserPost } from '../../components/HospitalDomain/UserPost';
import { User } from '../../components/HospitalDomain/User';
import { Patient } from '../../components/HospitalDomain/Patient/Patient';
import { Role } from "../../components/HospitalDomain/Role";
export default {
    name: "index",
    components: { Nav },
    data() {
        var organization = new Organization(this.TokenClient, this.Services.Authorization);
        var userTitle = new UserTitle(this.TokenClient, this.Services.Authorization);
        var userPost = new UserPost(this.TokenClient, this.Services.Authorization);
        var user = new User(this.TokenClient, this.Services.Authorization);
        var patient = new Patient(this.TokenClient, this.Services.Authorization);
        var role = new Role(this.TokenClient, this.Services.Authorization);
        return {
            RoleDomain: role,
            organizationDoamin: organization,
            userTitleDomain: userTitle,
            userPostDomain: userPost,
            userDomain: user,
            patientDomain: patient,
        }
    },
    mounted() {
        this.Init();
    },
    methods: {
        Init: function () {
            this.organizationDoamin.GetSessionOrganization();
            // this.userTitleDomain.GetSessionUserTitle();
            // this.userDomain.GetSessionUser();
            // this.patientDomain.GetSessionPatient();
            
        }
    }
}
</script>

<style scoped>
.index {
    height: 100%;
}

.container {
    height: 100%;
}

.main-box {
    background: #F5F6FA;
    /* border-radius: 25px 0 0 25px; */
}
</style>
